
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliateOnboardingUserGetStarted',
  components: {
    ValidationObserver,
  },
  layout: 'ruach',
  computed: {
    ...mapFields('user-onboarding', [
      'payload.firstName',
      'payload.middleName',
      'payload.lastName',
    ]),
    ...mapGetters(['userId', 'email']),
    ...mapGetters('user-onboarding', ['currentStepData', 'loading']),
  },
  mounted() {
    // When triggering analytics from a mounted hook, it is important to ensure that the analytics library is ready before sending the event.
    this.$analytics.ready(() => {
      this.sendSignUpEvent();
    });
  },
  methods: {
    ...mapActions('user-onboarding', ['saveName']),
    sendSignUpEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'sign_up',
        props: {
          // This property has been left here as a transitional measure. It will be removed in the future.
          userId: this.userId,
          user_id: this.userId,
          user: {
            email: this.email,
          },
        },
      });
    },
  },
};
