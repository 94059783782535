
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from '@/utilities';
import { WILL_STATUS } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateOnboardingIntrodution',
  layout: 'ruach',
  data() {
    return {
      isFeaturesOpen: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['userId', 'email']),
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('application/prices', ['willPrice', 'willTierTwoPrice']),
    ...mapGetters('user/contacts', ['userDetails']),
  },
  mounted() {
    this.getProductPrices();
    // When triggering analytics from a mounted hook, it is important to ensure that the analytics library is ready before sending the event.
    this.$analytics.ready(() => {
      this.sendCompleteOnboardingEvent();
    });
  },
  methods: {
    ...mapActions('application/prices', ['getProductPrices']),
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('will', ['updateWillStatus']),
    formatPrice,
    async startWill() {
      try {
        if (this.loading) return;

        this.loading = true;
        await this.addToCart({
          codes: ['WILL'],
          showSnackbar: false,
        });
        await this.updateWillStatus(WILL_STATUS.IN_PROGRESS);
        this.$router.push({
          path: this.localePath('/will/about-yourself/name'),
        });
      } finally {
        this.loading = false;
      }
    },
    toggleFeatures() {
      this.isFeaturesOpen = !this.isFeaturesOpen;
    },
    sendCompleteOnboardingEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_onboarding',
        props: {
          // This property has been left here as a transitional measure. It will be removed in the future.
          userId: this.userId,
          user_id: this.userId,
          user: {
            email: this.email,
            first_name: this.userDetails.firstName,
            last_name: this.userDetails.lastName,
            date_of_birth: Date.parse(this.userDetails.dateOfBirth) / 1000,
          },
        },
      });
    },
    skipWill() {
      const slug = `/${this.slug}`;
      this.$router.push({ path: this.localePath(slug) });
    },
  },
};
