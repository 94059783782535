
import { beneficiaries } from '@/mixins/apollo';

export default {
  name: 'AppWillEstateBackupSelector',
  mixins: [beneficiaries],
  props: {
    value: {
      default: null,
      type: String,
    },
  },
  computed: {
    options() {
      const hasMultipleHumanBeneficiaries =
        this.beneficiaries?.filter((beneficiary) => beneficiary.directoryPerson)
          .length > 1;
      const options = [
        {
          label: this.$t('components.estateBackupSelector.theirChildren'),
          value: 'children',
        },
        hasMultipleHumanBeneficiaries
          ? {
              label: this.$t(
                'components.estateBackupSelector.splitBetweenRemainingBeneficiaries'
              ),
              value: 'remaining',
            }
          : null,
        {
          label: this.$t(
            'components.estateBackupSelector.specificPeopleAndCharities'
          ),
          value: 'custom',
        },
      ].filter(Boolean);
      return options;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
