
import { DEMO_PAGES } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateGetStartedDemoIntroduction',
  computed: {
    figmaUrl() {
      return (
        DEMO_PAGES.INTRODUCTION.figmaUrl +
        '&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1'
      );
    },
  },
};
