import { render, staticRenderFns } from "./SendNotification.vue?vue&type=template&id=863949a0"
import script from "./SendNotification.vue?vue&type=script&lang=js"
export * from "./SendNotification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLoadingRing: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/LoadingRing.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppFormWrapper: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/FormWrapper.vue').default})
