
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import { formatDate } from '@/utilities';

import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

export default {
  name: 'PagesAffiliateAdminSubmissionsIndex',
  layout: 'admin',
  apollo: {
    partnershipWithAffiliateEnabledIds: {
      query: GET_PARTNERSHIPS_QUERY,
      variables: {
        filters: {
          affiliateEnabled: true,
        },
      },
      update: (data) => {
        const partnerships = data.getPartnerships ?? [];
        return [
          {
            text: '-',
            value: null,
          },
          ...partnerships.map((partnership) => ({
            text: partnership.name,
            value: partnership.id,
          })),
        ];
      },
      fetchPolicy: 'network-only',
    },
  },
  data() {
    return {
      columns: ['ID', 'Name', 'Email', 'Status', 'Updated'],
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Not Started',
          value: 'NOT_STARTED',
        },
        {
          label: 'In Progress',
          value: 'IN_PROGRESS',
        },
        {
          label: 'Awaiting Review',
          value: 'AWAITING_APPROVAL',
        },
        {
          label: 'Flagged',
          value: 'FLAGGED',
        },
        {
          label: 'Approved',
          value: 'APPROVED',
        },
        {
          label: 'Archived',
          value: 'ARCHIVED',
        },
      ],
      productAddOnFilters: [
        {
          text: 'All',
          value: '',
        },
        {
          text: 'Without Legal Advice Booking',
          value: 'WITHOUT_LEGAL_ADVICE_BOOKING',
        },
        {
          text: 'With Legal Advice Booking',
          value: 'WITH_LEGAL_ADVICE_BOOKING',
        },
        {
          text: 'With Legal Advice',
          value: 'WITH_LEGAL_ADVICE',
        },
        {
          text: 'Will',
          value: 'WILL',
        },
        {
          text: 'Will Tier Two',
          value: 'WILL_TIER_TWO',
        },
      ],
      partnershipWithAffiliateEnabledIds: [],
    };
  },
  computed: {
    ...mapGetters('admin/submissions', [
      'submissions',
      'submissionsLoading',
      'submissionsPageSize',
      'submissionsAffiliateId',
      'submissionsStatusFilter',
      'submissionsProductAddOnFilter',
      'submissionsPageIndex',
      'submissionsSearchQuery',
      'submissionsSort',
      'submissionsSortingDirection',
      'submissionsSortableColumns',
      'submissionsSortableFields',
      'submissionsTotalCount',
    ]),
    searchQuery() {
      return this.submissionsSearchQuery;
    },
    productAddOnFilter: {
      get() {
        return this.submissionsProductAddOnFilter;
      },
      set(value) {
        this.setSubmissionsProductAddOnFilter(value);
      },
    },
    searchByAffiliateId: {
      get() {
        return this.submissionsAffiliateId;
      },
      set(value) {
        this.setSubmissionsAffiliateId(value);
      },
    },
    pageCount() {
      return Math.ceil(this.submissionsTotalCount / this.submissionsPageSize);
    },
    paginatorFirst() {
      return this.submissionsPageIndex * this.submissionsPageSize;
    },
  },
  async mounted() {
    await this.fetchSubmissions();
  },
  methods: {
    ...mapActions('admin/submissions', [
      'fetchSubmissions',
      'setSubmissionsStatusFilter',
      'setSubmissionsProductAddOnFilter',
      'setSubmissionsSearchQuery',
      'setSubmissionsSort',
      'setSubmissionsAffiliateId',
      'setSubmissionsPageSize',
      'setSubmissionsPageIndex',
    ]),

    formatDate,
    search: debounce(300, async function (value) {
      await this.setSubmissionsSearchQuery(value.trim());
    }),
    async setPageIndex(pageState) {
      await this.setSubmissionsPageIndex(pageState.page);
    },
    async setPageIndexInput(e) {
      const value = parseInt(e.target.value);
      if (!isNaN(value) && value - 1 !== this.submissionsPageIndex) {
        await this.setSubmissionsPageIndex(value - 1);
      }
    },
  },
};
