
import { mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import { executors, will } from '@/mixins/apollo';

import EMAIL_EXECUTOR_TERMS_AND_CONDITIONS_MUTATION from '@/graphql/mutations/EmailExecutorTermsAndConditions';

export default {
  name: 'PagesAffiliateWillExecutorsProfessionalTerms',
  mixins: [executors, will],
  data() {
    return {
      hasReadTerms: false,
      agreesToTerms: false,
      isLoading: false,
      executorTermsAndConditionsIsVisible: false,
      scrollBottomIsVisible: true,
      successEmailIsVisible: false,
      executorFeesPanelIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userId', 'willId', 'email']),
    prevStep() {
      return this.willMeta.executors_option === 'professional'
        ? '/will/executors/options'
        : '/will/executors/primary-contact';
    },
    header() {
      return this.willMeta.executors_option === 'professional'
        ? this.$t('pages.will.executors.professionalTerms.headline')
        : this.$t(
            'pages.will.executors.professionalTerms.headlineFriendsAndFamily'
          );
    },
    isButtonDisabled() {
      return (
        this.executorTermsAndConditionsIsVisible ||
        this.executorFeesPanelIsVisible ||
        this.executorFeesCalculatorPanelIsVisible
      );
    },
    professionalSelected() {
      return this.willMeta.executors_option === 'professional';
    },
    submitLabel() {
      return this.professionalSelected
        ? this.$t('forms.labels.continue')
        : this.$t('forms.labels.continueToFuneral');
    },
    summaryContent() {
      return this.$t(
        'pages.will.executors.professionalTerms.summaryTerms'
      ).slice(0, this.professionalSelected ? 6 : 7);
    },
  },
  mounted() {
    if (this.willMeta.professional_executor_terms_agreed_at) {
      this.agreesToTerms = true;
      this.hasReadTerms = true;
    }
  },
  methods: {
    toggleExecutorTermsAndConditions() {
      this.executorTermsAndConditionsIsVisible =
        !this.executorTermsAndConditionsIsVisible;
    },
    toggleExecutorFeesPanel() {
      this.executorFeesPanelIsVisible = !this.executorFeesPanelIsVisible;
    },
    onScroll: debounce(100, function () {
      if (
        this.$refs.terms.scrollHeight -
          this.$refs.terms.scrollTop -
          this.$refs.terms.clientHeight <
        10
      ) {
        if (!this.hasReadTerms) {
          this.hasReadTerms = true;
        }
        this.scrollBottomIsVisible = false;
      } else {
        this.scrollBottomIsVisible = true;
      }
    }),
    goToBottom() {
      this.$refs.terms.scroll({
        top: this.$refs.terms.scrollHeight,
        behavior: 'smooth',
      });
    },
    goToTop() {
      this.$refs.terms.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },
    close() {
      this.toggleExecutorTermsAndConditions();
      this.scrollBottomIsVisible = true;
    },
    async emailTermsAndConditions() {
      this.hasReadTerms = true;
      this.successEmailIsVisible = true;
      await this.$apollo.mutate({
        mutation: EMAIL_EXECUTOR_TERMS_AND_CONDITIONS_MUTATION,
        variables: {
          userId: this.userId,
        },
      });
    },
    async onSubmit() {
      this.isLoading = true;
      if (this.professionalSelected) {
        this.willMeta.has_backup_executor = true;
      }
      if (
        this.agreesToTerms &&
        !this.willMeta.professional_executor_terms_agreed_at
      ) {
        this.willMeta.professional_executor_terms_agreed_at =
          new Date().toString();
      }
      await this.updateWillMeta();
      const path = this.localePath(
        this.professionalSelected ? '/will/executors/backup' : '/will/funeral'
      );
      if (!this.professionalSelected) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Executors',
          },
        });
      }

      this.$router.push({
        path,
      });
    },
  },
};
