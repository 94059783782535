import { render, staticRenderFns } from "./CharitySelector.vue?vue&type=template&id=a2cd19a0"
import script from "./CharitySelector.vue?vue&type=script&lang=js"
export * from "./CharitySelector.vue?vue&type=script&lang=js"
import style0 from "./CharitySelector.vue?vue&type=style&index=0&id=a2cd19a0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCharity: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Charity.vue').default,MetaSlot: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/MetaSlot.js').default,WpVerticalSelectButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/VerticalSelectButton.vue').default})
