
import cookies from 'js-cookie';
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliateOnboardingUserDetails',
  components: {
    ValidationObserver,
  },
  layout: 'ruach',
  computed: {
    ...mapFields('user-onboarding', [
      'payload.dateOfBirth',
      'payload.affiliateUserId',
      'payload.couponCode',
    ]),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('user-onboarding', ['currentStepData', 'loading']),
  },
  mounted() {
    this.setAffiliate();
  },
  methods: {
    ...mapActions('user-onboarding', ['saveDobAndAffiliateData']),
    setAffiliate() {
      const affiliateUserId = cookies.get('affiliate_user_id');

      if (affiliateUserId) {
        this.affiliateUserId = affiliateUserId;
      }
    },
  },
};
