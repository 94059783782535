
import { mapGetters, mapActions } from 'vuex';
import { metaArrayToObject, calculateTotalDistribution } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';
import { beneficiaries, charities, will } from '@/mixins/apollo';
import {
  RELATIONSHIP_TYPE,
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
} from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillEstateBeneficiaries',
  mixins: [beneficiaries, charities, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      VAULT_ITEM_KEY,
      VAULT_ITEM_TYPE,
      selectedContacts: [],
      selectedCharitiesAndCauses: [],
      selectedSuggestedCharities: [],
      selectedCauseIds: [],
      hasFamily: false,
      isHelpRequired: false,
    };
  },
  computed: {
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('application/causes', ['causes']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('user/contacts', ['contacts', 'contactsWithRelationship']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    showSuggestedCharities() {
      return !this.charities.length;
    },
    selectedBeneficiaries() {
      return this.selectedContacts.concat(this.selectedCharitiesAndCauses);
    },
    isComplete() {
      return !!(
        this.willMeta.estate_split &&
        (this.willMeta.estate_split !== 'custom' ||
          this.selectedBeneficiaries.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    possibleBeneficiaries() {
      return [this.beneficiaries, this.contacts, this.charities];
    },
    partnerContacts() {
      return this.contactsWithRelationship([RELATIONSHIP_TYPE.PARTNER]);
    },
    childContacts() {
      return this.contactsWithRelationship([RELATIONSHIP_TYPE.CHILD]);
    },
    displayedCauses() {
      const causeKeys = {};
      this.causes.forEach((cause) => {
        if (
          !this.willMeta.referral_charity ||
          this.selectedCauseIds.includes(cause.id)
        ) {
          causeKeys[cause.slug] = cause;
        }
      });

      this.beneficiaries.forEach((beneficiary) => {
        if (beneficiary.cause) {
          causeKeys[beneficiary.cause.slug] = beneficiary.cause;
        }
      });

      return Object.values(causeKeys);
    },
  },
  watch: {
    possibleBeneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaries.length
      ) {
        this.selectedContacts = this.contacts
          .filter((contact) => {
            return this.beneficiaries.find(
              (beneficiary) => beneficiary?.directoryPerson?.id === contact.id
            );
          })
          .map((contact) => contact.id);

        const charityIds = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        const causeIds = this.beneficiaries.reduce((ids, beneficiary) => {
          if (beneficiary.cause) {
            ids.push(beneficiary.cause.id);
          }
          return ids;
        }, []);

        this.selectedCharitiesAndCauses = charityIds.concat(causeIds);
      }
    },
    willMeta() {
      this.hasFamily = this.willMeta.has_partner || this.willMeta.has_children;
      if (!this.hasFamily) {
        this.willMeta.estate_split = 'custom';
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    updateSelectedCauseIds(value) {
      this.selectedCauseIds = value.slice();
      this.selectedCharitiesAndCauses = value;
    },
    async done() {
      const beneficiariesToRemove = [];
      const beneficiariesToUpdate = [];
      const beneficiariesToAdd = [];
      const availableDistribution = 100;
      let defaultDistribution;

      const saveVaultItem = this.isHelpAvailable
        ? this.saveHelpRequiredVaultItem({
            tool: this.willHelpAssessmentTool,
            vaultItemKey: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
            vaultItemType: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY,
            helpRequired: this.isHelpRequired,
          })
        : Promise.resolve();

      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaries.push(charity.id);
          }
        });
      }

      switch (this.willMeta.estate_split) {
        case 'partner':
        case 'children':
          this.charities.forEach((charity) =>
            beneficiariesToRemove.push(this.getBeneficiaryByCharity(charity))
          );
          this.displayedCauses.forEach((cause) =>
            beneficiariesToRemove.push(this.getBeneficiaryByCause(cause))
          );

          this.contacts
            .filter((contact) => {
              const contactIsPartner = this.partnerContacts.some(
                (partner) => partner.id === contact.id
              );
              const contactIsChild = this.childContacts.some(
                (child) => child.id === contact.id
              );
              return !contactIsChild && !contactIsPartner;
            })
            .forEach((contact) =>
              beneficiariesToRemove.push(this.getBeneficiaryByContact(contact))
            );

          switch (this.willMeta.estate_split) {
            case 'partner':
              defaultDistribution = availableDistribution;

              this.childContacts.forEach((child) =>
                beneficiariesToRemove.push(this.getBeneficiaryByContact(child))
              );

              this.partnerContacts.forEach((partner) => {
                const beneficiary = this.getBeneficiaryByContact(partner);
                if (beneficiary) {
                  beneficiariesToUpdate.push([
                    beneficiary,
                    defaultDistribution,
                  ]);
                } else {
                  beneficiariesToAdd.push([partner, defaultDistribution]);
                }
              });

              break;
            case 'children':
              this.partnerContacts.forEach((partner) =>
                beneficiariesToRemove.push(
                  this.getBeneficiaryByContact(partner)
                )
              );

              this.childContacts.forEach((child) => {
                const beneficiary = this.getBeneficiaryByContact(child);
                if (!beneficiary) {
                  beneficiariesToAdd.push([child, null]);
                }
              });

              break;
            default:
              break;
          }

          break;
        case 'custom': {
          for (const contact of this.contacts) {
            const beneficiary = this.getBeneficiaryByContact(contact);
            const isSelected = this.selectedContacts.includes(contact.id);

            if (isSelected) {
              if (!beneficiary) {
                beneficiariesToAdd.push([contact, null]);
              }
            } else if (beneficiary) {
              beneficiariesToRemove.push(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBeneficiaryByCharity(charity);
            const isSelected = this.selectedBeneficiaries.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                beneficiariesToAdd.push([charity, null]);
              }
            } else if (beneficiary) {
              beneficiariesToRemove.push(beneficiary);
            }
          }

          for (const cause of this.displayedCauses) {
            const beneficiary = this.getBeneficiaryByCause(cause);
            const isSelected = this.selectedBeneficiaries.includes(cause.id);

            if (isSelected) {
              if (!beneficiary) {
                beneficiariesToAdd.push([cause, null]);
              }
            } else if (beneficiary) {
              beneficiariesToRemove.push(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      try {
        await Promise.all(
          beneficiariesToRemove.map((beneficiaryToRemove) =>
            this.removeBeneficiary(beneficiaryToRemove)
          )
        );
        await Promise.all(
          beneficiariesToUpdate.map((beneficiaryToUpdate) =>
            this.updateBeneficiary(
              beneficiaryToUpdate[0],
              beneficiaryToUpdate[1]
            )
          )
        );
        await Promise.all([
          ...beneficiariesToAdd.map((beneficiaryToAdd) =>
            this.addBeneficiary(beneficiaryToAdd[0], beneficiaryToAdd[1])
          ),
          saveVaultItem,
        ]);

        const noCharity =
          this.willMeta.estate_split !== 'custom' ||
          !this.charities.find((charity) =>
            this.selectedBeneficiaries.includes(charity.id)
          );
        const noCause =
          this.willMeta.estate_split !== 'custom' ||
          !this.displayedCauses.find((cause) =>
            this.selectedBeneficiaries.includes(cause.id)
          );

        let nextPagePath;

        if (noCharity && noCause) {
          nextPagePath = '/will/estate/include-charity';
        } else {
          this.willMeta.charity_in_estate = 'true';
          await this.updateWillMeta();

          await this.refetchBeneficiaries();
          const currentPrimaryTotal = calculateTotalDistribution(
            this.beneficiaries.map((beneficiary) => beneficiary.distribution)
          );

          const mustRedistribute =
            this.willMeta.estate_split === 'custom' ||
            currentPrimaryTotal !== defaultDistribution;

          if (mustRedistribute) {
            nextPagePath = '/will/estate/distribution';
          } else {
            await this.updateWillIsPrimaryEstateSplitEvenly(false);
            nextPagePath = `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`;
          }
        }

        this.$nuxt.$emit('sendTrackingAttributes', {
          has_charity_gift: !noCharity,
        });
        this.sendBeneficiariesAddedEvent();

        this.$router.push({
          path: this.localePath(nextPagePath),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
        await this.refetchBeneficiaries();
      }
    },
  },
};
