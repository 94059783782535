
import { mapGetters } from 'vuex';
import { assets, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';
import EMAIL_BINDING_DEATH_NOMINATION from '@/graphql/mutations/EmailBindingDeathNomination';

export default {
  name: 'PagesAffiliateWillAssetsIndex',
  mixins: [assets, will],
  data() {
    return {
      defaultAsset: {},
      selectedAssets: [],
      isEmailBdnMessageVisible: false,
    };
  },
  computed: {
    ...mapGetters(['email']),
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    isComplete() {
      return !!(
        this.willMeta.has_assets === false || this.selectedAssets.length
      );
    },
    isBindingDeathNominationAvailable() {
      const allowedAffiliateSlugs = ['aware'];

      return (
        allowedAffiliateSlugs.includes(this.slug) &&
        this.$ff.bindingDeathNominationEnabled()
      );
    },
  },
  watch: {
    assets(assets) {
      if (assets.length && !this.selectedAssets.length) {
        this.selectedAssets = assets.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addFinancialInput() {
      this.selectedAssets.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeFinancialInput(asset) {
      if (this.isAsset(asset)) {
        await this.removeAsset(asset);
      }

      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
    },
    async done() {
      for (const asset of this.selectedAssets) {
        const isAsset = this.isAsset(asset);
        const meta = objectToMetaArray(asset.meta);

        if (this.willMeta.has_assets) {
          if (isAsset) {
            await this.updateAsset(asset, meta);
          } else {
            await this.addAsset(meta);
          }
        } else if (isAsset) {
          await this.removeAsset(asset);
        }
      }

      this.$router.push({
        path: this.localePath('/will/assets/liabilities'),
      });
    },

    showEmailBDNMessage() {
      this.isEmailBdnMessageVisible = true;

      setTimeout(() => {
        this.isEmailBdnMessageVisible = false;
      }, 6000);
    },

    async emailBDN() {
      const { data } = await this.$apollo.mutate({
        mutation: EMAIL_BINDING_DEATH_NOMINATION,
        variables: {
          partnershipSlug: this.slug,
        },
      });
      if (data.emailBindingDeathNomination) {
        this.showEmailBDNMessage();
      }
    },

    openAwareBdnLink() {
      const awareBdnLink =
        'https://aware.com.au/member/super/access-and-manage-your-account/nominate-a-beneficiary';
      window.open(awareBdnLink, '_blank', 'noopener, noreferrer');
    },
  },
};
