
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliateOnboardingUserCountry',
  components: {
    ValidationObserver,
  },
  layout: 'ruach',
  data() {
    return {
      countries: [
        {
          label: 'Australia',
          value: 'AU',
        },
        {
          label: 'New Zealand',
          value: 'NZ',
        },
      ],
    };
  },
  computed: {
    ...mapFields('user-onboarding', ['payload.country']),
    ...mapGetters('user-onboarding', ['currentStepData', 'loading']),
  },
  methods: {
    ...mapActions('user-onboarding', ['saveCountry']),
  },
};
