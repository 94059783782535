
import { mapActions, mapGetters } from 'vuex';
import { executors, will } from '@/mixins/apollo';
import { VAULT_ITEM_KEY } from '@/utilities/constants';
export default {
  name: 'PagesAffiliateWillExecutorsOptions',
  mixins: [executors, will],
  middleware({ store, redirect }) {
    if (!store.getters['user/contacts/userIsAustralian']) {
      return redirect('/will/executors');
    }
  },
  data() {
    return {
      VAULT_ITEM_KEY,
      videoPlayerState: {
        currentTime: 0,
        duration: 0,
        currentTimeString: 0,
        durationString: 0,
        played: false,
        expanded: false,
      },
      oldExecutorOption: undefined,
      executorTodoListIsVisible: false,
      executorComparisonPanelIsVisible: false,
      suggestedOptionIsVisible: false,
      executorFeesPanelIsVisible: false,
      isLoading: false,
      isHelpRequired: false,
      baseExecutorProducts: [
        'professional',
        'friendsFamilyAndProfessional',
        'friendsFamily',
      ],
    };
  },
  computed: {
    ...mapGetters('application/products', ['executorProducts']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('user/contacts', ['contacts']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    isDefaultExecutorOptionSelected() {
      return (
        !!this.willMeta.executors_option &&
        this.baseExecutorProducts.includes(this.willMeta.executors_option)
      );
    },
    videoUrl() {
      return 'https://storage.googleapis.com/docs-safewill-operation-australia-southeast1/Executor-Video.mp4#t=0.1';
    },
    videoSubtitlesUrl() {
      return 'https://storage.googleapis.com/docs-safewill-operation-australia-southeast1/Executor-Video-subtitles.vtt';
    },
    executorProductsOptions() {
      return this.$ff.ptgActExecutorOption()
        ? this.mapExecutorProductsForRender(this.executorProducts)
        : [];
    },
    executorOptions() {
      return [
        ...this.mapExecutorProductsForRender(
          this.baseExecutorProducts.map((execCode) => ({
            code: execCode,
          }))
        ),
        ...this.executorProductsOptions,
      ];
    },
  },
  watch: {
    'willMeta.executors_option': function (newValue) {
      if (newValue === null && this.executorProductsOptions.length > 0) {
        this.willMeta.executors_option = this.executorProductsOptions[0].value;
      }

      if (this.oldExecutorOption == null) {
        this.oldExecutorOption = newValue;
      }
    },
  },
  mounted() {
    this.oldExecutorOption = this.willMeta.executors_option;
  },
  methods: {
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    onVideoCompletion() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        watchedExecutorVideo: 'True',
      });
    },
    mapExecutorProductsForRender(executorProducts) {
      return executorProducts.map((product) => ({
        label: this.$t(`pages.will.executors.options.${product.code}.label`),
        description: [
          this.$t(`pages.will.executors.options.${product.code}.description`),
          ...(this.$te(
            `pages.will.executors.options.${product.code}.descriptionTwo`
          )
            ? [
                this.$t(
                  `pages.will.executors.options.${product.code}.descriptionTwo`
                ),
              ]
            : []),
        ],
        value: product.code,
      }));
    },
    toggleExecutorToDoList() {
      this.executorTodoListIsVisible = !this.executorTodoListIsVisible;
    },
    toggleExecutorFeesPanel() {
      this.executorFeesPanelIsVisible = !this.executorFeesPanelIsVisible;
    },
    async onSubmit() {
      this.isLoading = true;

      const saveVaultItem = this.isHelpAvailable
        ? this.saveHelpRequiredVaultItem({
            tool: this.willHelpAssessmentTool,
            vaultItemKey: VAULT_ITEM_KEY.DECISION_MAKER_DEATH,
            helpRequired: this.isHelpRequired,
          })
        : Promise.resolve();

      let path;
      if (
        ['friendsFamilyAndProfessional', 'professional'].includes(
          this.willMeta.executors_option
        ) ||
        !this.willMeta.professional_executor_terms_agreed_at
      ) {
        this.willMeta.professional_executor_terms_agreed_at = null;
      }

      switch (this.willMeta.executors_option) {
        case 'friendsFamily':
          path = '/will/executors/primary';
          break;
        case 'professional':
          path = '/will/executors/professional-terms';
          this.willMeta.has_backup_executor = false;
          if (this.oldExecutorOption !== 'professional') {
            await Promise.all(
              this.contacts.map((contact) => {
                if (
                  this.isPrimaryExecutor(contact) ||
                  this.isBackupExecutor(contact)
                ) {
                  return this.removeExecutor(contact);
                }
                return Promise.resolve();
              })
            );
          }

          break;
        case 'friendsFamilyAndProfessional':
          path = '/will/executors/primary-contact';
          this.willMeta.has_backup_executor = false;

          await Promise.all(
            this.contacts.map((contact) => {
              if (this.isBackupExecutor(contact)) {
                return this.removeExecutor(contact);
              }
              return Promise.resolve();
            })
          );

          break;
        default:
          path = '/will/executors/backup';
          this.willMeta.has_backup_executor = true;

          await Promise.all(
            this.contacts.map((contact) => {
              if (this.isPrimaryExecutor(contact)) {
                return this.removeExecutor(contact);
              }
              return Promise.resolve();
            })
          );
          break;
      }

      await Promise.all([this.updateWillMeta(), saveVaultItem]);

      this.$router.push({ path: this.localePath(path) });
    },
  },
};
