import { render, staticRenderFns } from "./_step.vue?vue&type=template&id=00734132"
import script from "./_step.vue?vue&type=script&lang=js"
export * from "./_step.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppGetStartedPrototype: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/get-started/Prototype.vue').default})
