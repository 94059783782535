
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { formatPrice } from '@/utilities';
import {
  WILL_BASE_PRICE,
  COUNTRY,
  ONBOARDING_STEP,
} from '@/utilities/constants';

export default {
  name: 'PagesAffiliateOnboardingUserFinish',
  layout: 'ruach',
  computed: {
    ...mapFields('user-onboarding', ['payload.firstName', 'payload.country']),
    ...mapGetters(['willId', 'userId', 'email']),
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('application/banners', ['showOver60Banner']),
    ...mapGetters('application/prices', ['willPrice']),
    ...mapGetters('user-onboarding', ['loading']),
    ...mapGetters('user/contacts', ['userDetails']),
    headlineWithWillPrice() {
      const name = this.firstName;
      const price = formatPrice(this.willPrice);

      // TODO get the base price from the API https://safewill.atlassian.net/browse/DEV-5802
      if (this.willPrice < WILL_BASE_PRICE) {
        return this.$t('pages.onboarding.newUser.page4.headlineDiscounted', {
          name,
          basePrice: formatPrice(WILL_BASE_PRICE),
          price,
        });
      }

      return this.$t('pages.onboarding.newUser.page4.headlineBase', {
        name,
        price,
      });
    },
  },
  mounted() {
    this.getProductPrices();
    this.initOnboarding(ONBOARDING_STEP.FINISH);
    // When triggering analytics from a mounted hook, it is important to ensure that the analytics library is ready before sending the event.
    this.$analytics.ready(() => {
      this.sendCompleteOnboardingEvent();
    });
  },
  methods: {
    ...mapActions(['setCharitySource', 'setWillStatus']),
    ...mapActions('application/prices', ['getProductPrices']),
    ...mapActions('user-onboarding', ['initOnboarding', 'setLoading']),
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('will', ['updateWillStatus']),
    sendCompleteOnboardingEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_onboarding',
        props: {
          // This property has been left here as a transitional measure. It will be removed in the future.
          userId: this.userId,
          user_id: this.userId,
          user: {
            email: this.email,
            first_name: this.userDetails.firstName,
            last_name: this.userDetails.lastName,
            date_of_birth: Date.parse(this.userDetails.dateOfBirth) / 1000,
          },
        },
      });
    },
    async nextStep(bypassDashboard = false) {
      this.setLoading(true);

      let next = '';
      if (this.country === COUNTRY.NZ) {
        next += '/nz';
      }
      if (this.slug) {
        next += `/${this.slug}`;
      }
      if (bypassDashboard) {
        await Promise.all([
          this.addToCart({ codes: ['WILL'], showSnackbar: false }),
          this.updateWillStatus('IN_PROGRESS'),
        ]);
        next += '/will/about-yourself/name';
      }

      this.setLoading(false);
      this.$router.push({
        path: next,
      });
    },
  },
};
