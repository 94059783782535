
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralLocation',
  mixins: [will],

  computed: {
    submitLabel() {
      return this.willMeta.funeral_location
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    getHeadingText() {
      return this.willMeta.funeral_type === 'cremation'
        ? this.$t('pages.will.funeral.chooseAshesLocation')
        : this.$t('pages.will.funeral.chooseBurialLocation');
    },
  },
  methods: {
    onDone() {
      this.$router.push({
        path: this.localePath('/will/funeral/other-requests'),
      });
    },
  },
};
