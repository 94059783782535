
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';
import { formatPrice } from '@/utilities';

export default {
  name: 'PagesAffiliateIndex',
  mixins: [will],
  layout: 'ruach',
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapGetters('application/prices', ['willPrice', 'willGiftPrice']),
    ...mapGetters('user/orders', ['purchasedProductCodes']),
    ...mapGetters('user/invites', ['invites']),
    giftWillBuyButtonLabel() {
      const willGiftPrice = this.formatPrice(this.willGiftPrice);
      const willPrice = `<strike>${this.formatPrice(this.willPrice)}</strike>`;
      return this.$t('pages.dashboard.giftNotification.buyAGift', {
        willGiftPrice,
        willPrice,
      });
    },
    giftWillSendButtonLabel() {
      return this.$t('pages.dashboard.giftNotification.sendAGift');
    },
    sentGifts() {
      const sentInvites = this.invites
        ?.filter(({ type }) => {
          return type === 'PAID';
        })
        .flatMap(({ discountProductCodes }) => discountProductCodes);
      return sentInvites.reduce((sentGifts, product1) => {
        sentGifts[product1] = sentInvites.filter(
          (product2) => product1 === product2
        ).length;
        return sentGifts;
      }, {});
    },
    remainingWillGifts() {
      if (!this.purchasedProductCodes) {
        return null;
      }
      const purchasedProductCount = this.purchasedProductCodes.reduce(
        (acc, gift) => {
          acc[gift] = (acc[gift] || 0) + 1;
          return acc;
        },
        {}
      );
      const willGiftCount = purchasedProductCount.WILL_GIFT || 0;
      const willInviteCount = this.sentGifts.WILL || 0;
      const willsRemaining = willGiftCount - willInviteCount;

      return willsRemaining > 0;
    },
  },
  methods: {
    formatPrice,
    openGiftsPage() {
      this.$router.push({
        path: this.localePath('/gifts'),
      });
    },
    openInvitePage() {
      this.$router.push({
        path: this.localePath('/dashboard/invites'),
      });
    },
  },
};
