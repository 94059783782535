
import { mapActions } from 'vuex';
import { showChat } from '@/modules/chat';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWelcomeModal',
  mixins: [will],
  data() {
    return {
      step: 0,
      totalSteps: 4,
    };
  },
  computed: {
    nextId() {
      if (this.step === this.totalSteps - 1) {
        return 'id';
      }
      return 'next';
    },
    nextText() {
      if (this.step === 0) {
        return this.$t('components.welcomeModal.howItWork');
      } else if (this.step === this.totalSteps - 1) {
        return this.$t('components.welcomeModal.okay');
      }
      return this.$t('components.welcomeModal.next');
    },
    prevId() {
      if (this.step === 0) {
        return 'skip';
      }
      return 'back';
    },
    prevText() {
      return this.step === 0
        ? this.$t('components.welcomeModal.skip')
        : this.$t('components.welcomeModal.back');
    },
  },
  methods: {
    showChat,
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('will', ['updateWillStatus']),
    async close() {
      this.willMeta.show_welcome_modal = false;
      this.updateWillMeta();
      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      await this.updateWillStatus('IN_PROGRESS');
    },
    nextStep() {
      this.$refs.container.style.minHeight = `${this.$refs.container.offsetHeight}px`;
      if (this.step < this.totalSteps - 1) {
        this.step++;
      } else {
        this.close();
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.close();
      } else {
        this.step--;
      }
    },
  },
};
