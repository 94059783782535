
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  mixins: [will],
  data() {
    return {
      userHasIntendedToSign: false,
    };
  },
  computed: {
    ...mapGetters('user/orders', ['isLatestProductPurchased']),
    intentToSign: {
      get() {
        return this.willMeta.intent_to_sign?.toString();
      },
      set(newValue) {
        this.willMeta.intent_to_sign = newValue;
        this.isDirty = true;
      },
    },
    hasProfessionalExecutorSelected() {
      return ['professional', 'friendsFamilyAndProfessional'].includes(
        this.willMeta.executors_option
      );
    },
    isWillProductLatestPurchased() {
      return ['WILL', 'WILL_TIER_TWO'].some((product) =>
        this.isLatestProductPurchased(product)
      );
    },
    showIntentionToSign() {
      return (
        this.hasProfessionalExecutorSelected &&
        this.isWillProductLatestPurchased
      );
    },
    intendedToSign() {
      return this.intentToSign === 'true';
    },
    intentionToSignOptions() {
      return [
        {
          label: this.$t('components.intentionToSign.options.yes'),
          value: 'true',
        },
        {
          label: this.$t('components.intentionToSign.options.no'),
          value: 'false',
        },
      ];
    },
  },
  watch: {
    showIntentionToSign(value) {
      if (value) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'intention_to_sign_shown',
        });
      }
    },
  },
  methods: {
    async updateIntentToSign() {
      try {
        await this.updateWillMeta();
        this.userHasIntendedToSign = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
