
import { mapActions, mapGetters } from 'vuex';
import { purchasedProducts, will } from '@/mixins/apollo';

export default {
  name: 'AppRecommendedAction',
  mixins: [purchasedProducts, will],
  data() {
    return {
      buttonActionsWithConditions: [
        {
          condition: () =>
            this.willStatus === 'IN_PROGRESS' && this.willProgress.complete,
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.submitWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () => this.willStatus === 'IN_PROGRESS',
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completeWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () => this.willStatus === 'NOT_STARTED',
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.startWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () =>
            this.willStatus === 'AWAITING_APPROVAL' &&
            !this.isPoaPurchased &&
            this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completePOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () => this.willStatus === 'FLAGGED',
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.editWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () => !this.poaId && this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('commons.text.startPoa'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () => this.poaId && !this.poaProgress.complete,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completePOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () =>
            this.poaProgress.complete && this.poaProgress.decimal < 1,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.submitPOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () => !this.hasEndOfLifeItems,
          isProductEnabled: () => this.features.endOfLifeEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.startVault'),
            link: this.localePath('/end-of-life'),
          },
        },
        {
          condition: () => true,
          isProductEnabled: () => this.features.endOfLifeEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.continueVault'),
            link: this.localePath('/end-of-life'),
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['willStatus']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    ...mapGetters('user/end-of-life', ['endOfLifeCategoryItemCounts']),
    ...mapGetters('user/poa', { poaId: 'poaId', poaStatus: 'status' }),
    ...mapGetters('user/progress', {
      willProgress: 'will',
      poaProgress: 'poa',
    }),
    action() {
      const possibleActions = this.buttonActionsWithConditions.filter(
        (action) => {
          return (
            action.condition.call(this) && action.isProductEnabled.call(this)
          );
        }
      );
      return possibleActions[0]?.buttonActions || null;
    },
    hasEndOfLifeItems() {
      return Object.values(this.endOfLifeCategoryItemCounts).some(
        (categoryCount) => categoryCount > 0
      );
    },
  },
  async created() {
    await Promise.all([
      this.getEndOfLifeCategories(),
      this.getEndOfLifeCategoryItemCounts(),
    ]);
  },
  methods: {
    ...mapActions('application/end-of-life', ['getEndOfLifeCategories']),
    ...mapActions('user/end-of-life', ['getEndOfLifeCategoryItemCounts']),
  },
};
