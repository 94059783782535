
const checkoutItems = [
  {
    product: 'UPDATE_CREDIT_CARD_DETAILS',
    finalPrice: 1500,
    type: 'DEFAULT',
  },
];

export default {
  name: 'PagesAffiliateCheckoutUpdateCardIndex',
  data() {
    return {
      success: false,
      checkoutItems,
    };
  },
  methods: {
    ordersCreationSuccessfulHandler() {
      this.$router.push({
        path: this.localePath('/checkout/update-card/thanks'),
      });
    },
  },
};
