
import { PLAN_TYPE, WILL_MODULES_FLAG_KEYS } from '@/utilities/constants';

export default {
  name: 'AppModuleStep',
  props: {
    prevStep: {
      default: null,
      type: String,
    },
    plan: {
      default: PLAN_TYPE.WILL,
      type: String,
      validator: (value) => Object.values(PLAN_TYPE).includes(value),
    },
    moduleKey: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      WILL_MODULES_FLAG_KEYS,
    };
  },
  computed: {
    moduleFlagKey() {
      return this.WILL_MODULES_FLAG_KEYS[this.moduleKey];
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: this.localePath(this.prevStep),
        query: this.$route.query,
      });
    },
  },
};
