
import { mapGetters } from 'vuex';
import { beneficiaries, will } from '@/mixins/apollo';
import { dobIsUnderAge } from '@/utilities';

export default {
  key(route) {
    return route.fullPath;
  },
  mixins: [beneficiaries, will],
  data() {
    return {
      distributions: [],
      valid: false,
      isBackupEstateSplitEvenly: false,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('application/ui', ['shouldShowCharityConsentPage']),
    ...mapGetters('user/contacts', ['isUserChild']),
    isComplete() {
      return !!this.valid;
    },
    willTierTwoAddOnIsVisible() {
      return this.beneficiaries.some(
        ({ directoryPerson }) =>
          !!directoryPerson &&
          (this.isUserChild(directoryPerson) ||
            (!directoryPerson.dateOfBirth &&
              !directoryPerson.ageEighteenOrAbove) ||
            dobIsUnderAge(directoryPerson.dateOfBirth, 22))
      );
    },
    currentBeneficiary() {
      return this.beneficiaries.find(
        (beneficiary) => beneficiary.id === this.$route.params.id
      );
    },
    currentIndex() {
      return this.backupQueue.findIndex(
        (beneficiary) => beneficiary.id === this.$route.params.id
      );
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex];
      return beneficiary
        ? `/will/estate/backup-beneficiaries/${beneficiary.id}`
        : '/will/estate/beneficiaries';
    },
    validBeneficiaries() {
      return this.currentBeneficiary?.backup.filter((beneficiary) => {
        if (beneficiary.directoryPerson && this.currentBeneficiary) {
          return (
            beneficiary.directoryPerson.id !==
            this.currentBeneficiary.directoryPerson.id
          );
        }
        return true;
      });
    },
    submitLabel() {
      return this.$t('forms.labels.continue');
    },
  },
  watch: {
    beneficiaries(newBeneficiariesValue) {
      const currentBeneficiary = newBeneficiariesValue.find(
        (beneficiary) => beneficiary.id === this.$route.params.id
      );
      this.isBackupEstateSplitEvenly =
        currentBeneficiary.isBackupEstateSplitEvenly;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await this.updateBeneficiaryCore(
        this.currentBeneficiary,
        this.currentBeneficiary.distribution,
        this.isBackupEstateSplitEvenly
      );
      await Promise.all(
        this.currentBeneficiary.backup.map((beneficiary, i) => {
          return this.updateBeneficiaryCore(
            beneficiary,
            this.isBackupEstateSplitEvenly ? null : this.distributions[i],
            beneficiary.isBackupEstateSplitEvenly
          );
        })
      );
      await this.refetchBeneficiaries();

      if (this.currentIndex < this.backupQueue.length - 1) {
        this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${
              this.backupQueue[this.currentIndex + 1].id
            }`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Estate',
          },
        });

        await this.refetchWill();
        const path = this.localePath(
          this.shouldShowCharityConsentPage
            ? '/will/charity-consent?next-module=gifts'
            : '/will/gifts'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
