
import { mapGetters } from 'vuex';
import { DEMO_PAGES } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateGetStartedDemoStep',
  computed: {
    ...mapGetters('application/affiliate', ['slug']),
    prototypeLink() {
      return Object.values(DEMO_PAGES).find(
        (page) =>
          this.localePath(`/${this.slug}${page.url}`) === this.$route.path
      )?.figmaUrl;
    },
  },
  mounted() {
    if (!this.prototypeLink) {
      this.$router.push(
        this.localePath(`/${this.slug}${DEMO_PAGES.INTRODUCTION.url}`)
      );
    }
  },
};
