
import { mapActions, mapGetters } from 'vuex';
import { guardians, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE, VAULT_ITEM_KEY } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGuardiansBackup',
  mixins: [guardians, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      VAULT_ITEM_KEY,
      selectedContacts: [],
      isHelpRequired: false,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('user/contacts', ['contacts']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    isComplete() {
      return !!(
        this.willMeta.has_backup_guardian === false ||
        this.selectedContacts.length
      );
    },
    excludedContacts() {
      return this.primaryGuardians.map((guardian) => {
        return guardian.directoryPerson.id;
      });
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    backupGuardians() {
      this.updateSelectedContact();
    },
  },
  mounted() {
    this.updateSelectedContact();
  },
  methods: {
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    updateSelectedContact() {
      if (this.backupGuardians?.length) {
        this.selectedContacts = this.contacts
          .filter((contact) => {
            return this.isBackupGuardian(contact);
          })
          .map((contact) => contact.id);
      }
    },
    async done() {
      const contactsToRemove = [];
      const contactsToAdd = [];

      const saveVaultItem = this.isHelpAvailable
        ? this.saveHelpRequiredVaultItem({
            tool: this.willHelpAssessmentTool,
            vaultItemKey: VAULT_ITEM_KEY.DECISION_MAKER_GUARDIANSHIP_CHILD,
            helpRequired: this.isHelpRequired,
          })
        : Promise.resolve();

      for (const contact of this.contacts) {
        const isBackupGuardian = this.isBackupGuardian(contact);
        const isSelected = this.selectedContacts.includes(contact.id);

        if (this.willMeta.has_backup_guardian && isSelected) {
          if (!isBackupGuardian) {
            contactsToAdd.push(contact);
          }
        } else if (isBackupGuardian) {
          contactsToRemove.push(contact);
        }
      }

      try {
        await Promise.all(
          contactsToRemove.map((contact) => this.removeGuardian(contact))
        );
        await Promise.all([
          ...contactsToAdd.map((contact) =>
            this.addGuardian(contact, 'backup')
          ),
          saveVaultItem,
        ]);
        this.$router.push({
          path: this.localePath('/will/guardians/your-pets'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
