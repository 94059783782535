import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=76096860"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppLoading: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Loading.vue').default,AppSimpleTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/SimpleTable.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default,AppUserHistoricalWills: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/UserHistoricalWills.vue').default,AppCartTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CartTable.vue').default,AppOrdersDetails: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/OrdersDetails.vue').default,AppCardSection: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CardSection.vue').default,AppActionLink: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/ActionLink.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBaseButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/BaseButton.vue').default,AppCardRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CardRow.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default})
