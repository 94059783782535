
import { mapGetters } from 'vuex';

export default {
  name: 'AppAccountInviteFriendsAndFamily',
  props: {
    resendable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    cancelable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('user/contacts', ['userPartner', 'contacts']),
    ...mapGetters('user/invites', ['invites']),
    alreadyInvitedUsers() {
      const inviteMap = this.invites.reduce((acc, cur) => {
        acc[cur.email] = true;
        return acc;
      }, {});

      const excludeList = [];
      for (const contact of this.contacts) {
        let isInvited = false;
        for (const email of contact.emails) {
          if (inviteMap[email.value]) {
            isInvited = true;
          }
        }
        if (isInvited) {
          excludeList.push(contact.id);
        }
      }
      return excludeList;
    },
    defaultInvites() {
      return this.invites
        .filter((invite) => invite.type === 'DEFAULT')
        .reverse();
    },
    friends() {
      return this.contacts
        .filter((contact) => {
          const isPartner = contact.id === this.userPartner?.id;
          const isOver18 = contact.over18;
          const hasDefaultEmail = !!contact.defaultEmailAddress;
          const isAlreadyInvited = this.invites.some(
            (invite) => invite.email === contact.defaultEmailAddress
          );
          return !isPartner && isOver18 && hasDefaultEmail && !isAlreadyInvited;
        })
        .map((contact) => contact.defaultEmailAddress);
    },
  },
};
