
import cookies from 'js-cookie';
import { mapActions } from 'vuex';
import { removeLegacyCookie } from '@/modules/cookies';
import { sanitizeLogInRedirect } from '@/utilities/allowed-redirects';
import {
  retrieveUtmQueryParameters,
  saveAnonymousProfile,
  getAnonymousProfile,
} from '@/utilities';

export default {
  name: 'PagesAffiliateLogInIndex',
  middleware: ['redirect-if-logged-in.middleware'],
  mounted() {
    removeLegacyCookie();
    this.$apolloHelpers.onLogout();
    this.resetUserState();
    this.resetCheckoutState();
    this.$analytics.reset();

    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        cookies.set('referral_charity', this.$route.query.charity);
        saveAnonymousProfile({ referralCharity: this.referral_charity });
      }

      if (this.$route.query.redirect) {
        const where = sanitizeLogInRedirect(String(this.$route.query.redirect));

        if (where) {
          cookies.set('redirect', where);
        }
      }
    }

    const utmParams =
      retrieveUtmQueryParameters(this.$route.query) ??
      getAnonymousProfile()?.utm;
    const hasUtmCookie = cookies.get('has_utm');

    if (utmParams) {
      saveAnonymousProfile({
        utm: utmParams,
      });
      cookies.set('has_utm', 'true');
      this.$auth.$storage.setUniversal(
        'redirect',
        `/?${new URLSearchParams(utmParams).toString()}`
      );
    } else if (!hasUtmCookie) {
      saveAnonymousProfile({ utm: {} });
    }

    this.$auth.loginWith('auth0', { params: { prompt: 'login' } });
  },
  beforeDestroy() {
    this.resetUI();
  },
  methods: {
    ...mapActions(['resetUserState']),
    ...mapActions('application/ui', ['resetUI']),
    ...mapActions('checkout', ['resetCheckoutState']),
  },
};
