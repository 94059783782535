
export default {
  name: 'AppGetStartedPrototype',
  props: {
    prototypeLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screenHeight: window.innerHeight,
      headerHeight: 0,
    };
  },
  computed: {
    prototypeHeight() {
      const padding = 4;
      return this.screenHeight - this.headerHeight - padding;
    },
  },
  mounted() {
    this.headerHeight = this.$refs.demonstrationHeader.clientHeight;
    window.addEventListener('resize', () => {
      this.screenHeight = window.innerHeight;
    });
  },
  methods: {
    openSignup() {
      this.$auth.loginWith('auth0', { params: { screen_hint: 'signup' } });
    },
  },
};
