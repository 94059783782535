
import { mapActions, mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGuardiansPetGuardians',
  mixins: [will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
    };
  },
  computed: {
    ...mapGetters('user/contacts', ['contacts']),
    ...mapGetters('user/pets', ['pets']),
    isComplete() {
      const assignedPets = this.selectedContacts.filter((arr) => {
        return arr.length;
      });
      return !!(
        this.willMeta.has_pet_guardians === false ||
        assignedPets.length === this.pets.length
      );
    },
  },
  async created() {
    await this.getPets();
    this.updateSelectedPetGuardians();
  },
  methods: {
    ...mapActions('user/pets', [
      'getPets',
      'addPetGuardian',
      'removePetGuardian',
    ]),
    updateSelectedPetGuardians() {
      this.selectedContacts = this.pets.map((pet) => {
        const contact = this.contacts.find(
          (contact) => pet?.guardianDirectoryPerson?.id === contact.id
        );
        return contact ? [contact.id] : [];
      });
    },
    async done() {
      try {
        await Promise.all(
          this.pets.map((pet, i) => {
            const selectedGuardian = this.selectedContacts[i][0];
            if (this.willMeta.has_pet_guardians) {
              if (
                !pet.guardianDirectoryPerson ||
                pet.guardianDirectoryPerson.id !== selectedGuardian
              ) {
                return this.addPetGuardian({
                  petId: pet.id,
                  personId: selectedGuardian,
                });
              }
            } else if (pet.guardianDirectoryPerson) {
              return this.removePetGuardian(pet.id);
            }
            return Promise.resolve();
          })
        );
        this.updateSelectedPetGuardians();
        this.$router.push({
          path: this.localePath('/will/guardians/pet-care-fund'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
