import { render, staticRenderFns } from "./invites.vue?vue&type=template&id=13b943fc"
import script from "./invites.vue?vue&type=script&lang=js"
export * from "./invites.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppAccountSendGifts: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/SendGifts.vue').default,AppAccountSentGifts: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/SentGifts.vue').default,AppAccountOrders: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/Orders.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppAccountInvite: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/Invite.vue').default,AppTabs: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tabs.vue').default})
