
import { mapGetters, mapActions } from 'vuex';
import { formatError } from '@/utilities';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';
import CREATE_PAID_INVITE_MUTATION from '@/graphql/mutations/CreatePaidInvite';
import INVITE_CONTACTS_MUTATION from '@/graphql/mutations/InviteContacts';

export default {
  name: 'AppAccountSendInvite',

  props: {
    type: {
      default: 'DEFAULT',
      type: String,
    },
    isIncomplete: {
      default: false,
      type: Boolean,
    },
    products: {
      type: Array,
      default: () => ['WILL'],
    },
    excludedContacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      email: '',
      selectedContacts: [],
      isBusy: false,
      showAlert: null,
      alertTheme: null,
      alertMessage: null,
      isInvalid: null,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('admin/masquerade', ['isMasquerading']),
    ...mapGetters('user/contacts', ['userPartner', 'userHasPartner']),
    ...mapGetters('user/invites', ['invites']),
    isPartner() {
      return this.type === 'PARTNER';
    },
    isPaid() {
      return this.type === 'PAID';
    },
    contactSelectorText() {
      return this.isPartner
        ? this.$tc('components.contactForm.contactLabels.partner')
        : this.$tc('components.contactForm.contactLabels.person');
    },
    maxContactSelection() {
      return this.isPartner || this.isPaid ? 1 : 10;
    },
    relationshipTypeForContactSelector() {
      return this.isPartner ? RELATIONSHIP_TYPE.PARTNER : null;
    },
  },
  mounted() {
    this.initSelectedPartnerContact();
  },
  methods: {
    ...mapActions('user/contacts', ['getDirectoryPersonsByOwnerId']),
    ...mapActions('user/invites', ['getInvites']),
    initSelectedPartnerContact() {
      this.selectedContacts =
        this.isPartner && this.userHasPartner ? [this.userPartner.id] : [];
    },
    async sendInvite() {
      this.isBusy = true;
      this.alertMessage = null;
      try {
        if (this.isPaid) {
          await this.$apollo.mutate({
            mutation: CREATE_PAID_INVITE_MUTATION,
            variables: {
              ownerId: this.userId,
              contactId: this.selectedContacts[0],
              productCodes: this.products,
            },
            update: () => {
              this.getInvites();
            },
          });
        } else {
          await this.$apollo.mutate({
            mutation: INVITE_CONTACTS_MUTATION,
            variables: {
              ownerId: this.userId,
              contactIds: this.selectedContacts,
            },
            update: () => {
              this.getInvites();
            },
          });

          this.$nuxt.$emit('sendTrackingAttributes', {
            partner_invite_created_frontend: this.invites.some(
              (i) => i.type === 'PARTNER'
            ),
            friend_invite_created_frontend: this.invites.some(
              (i) => i.type === 'DEFAULT'
            ),
            number_of_invitations: this.invites.filter((i) =>
              ['PARTNER', 'DEFAULT'].includes(i.type)
            ).length,
          });
        }

        if (!this.isPartner) {
          this.selectedContacts = [];
        }

        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('components.sendInvite.successfullySentInvite'),
        });
        this.$emit('complete');
      } catch (error) {
        this.alertTheme = 'error';
        this.alertMessage = this.$te(formatError(error.message))
          ? this.$t(formatError(error.message))
          : formatError(error.message);
        this.$emit('error', this.alertMessage);
      }
      this.isBusy = false;
    },
  },
};
