import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e72f6594"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=e72f6594&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppDataTableFilter: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableFilter.vue').default,AppDataTableCell: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableCell.vue').default,AppStatusChip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/StatusChip.vue').default,AppDataTableRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableRow.vue').default,AppDataTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTable.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default})
