
import { mapGetters, mapActions } from 'vuex';
import startCase from 'lodash/startCase';
import {
  formatError,
  isAdminOrHigher,
  getSubordinateRoleOptionsByRole,
  getSubordinateRolesByRole,
  supportedCountryOptions,
} from '@/utilities';

import GET_USER_QUERY from '@/graphql/queries/GetUser';
import UPDATE_USER_ROLE_MUTATION from '@/graphql/mutations/UpdateUserRole';
import GET_WILLS_QUERY from '@/graphql/queries/GetWills';
import GET_WILL_BY_EMAIL from '@/graphql/queries/GetWillByEmail';
import DELETE_USER from '@/graphql/mutations/DeleteUser';
import UPDATE_DIRECTORY_COUNTRY_MUTATION from '@/graphql/mutations/UpdateDirectoryCountry';
import GET_CART_ITEMS_BY_USER_ID_QUERY from '@/graphql/queries/GetCartItemsByUserId';
import UPDATE_USER_ADMIN from '@/graphql/mutations/UpdateUserAdmin';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import GET_INVITES from '@/graphql/queries/GetInvites';
import FORCE_VERIFY from '@/graphql/mutations/ForceVerify';

import { subscription } from '@/mixins/apollo';
import orders from '@/mixins/apollo/orders';

export default {
  name: 'PagesAffiliateAdminUsersId',
  mixins: [orders, subscription],
  layout: 'admin',
  apollo: {
    will: {
      query: GET_WILL_BY_EMAIL,
      fetchPolicy: 'network-only',
      variables() {
        return {
          email: this.user?.email,
        };
      },
      skip() {
        return !this.isUserConsumer || !this.user?.email;
      },
      update: (data) => {
        if (data.getWillByEmail) {
          return data.getWillByEmail.will;
        }
        return null;
      },
    },
    historicalWills: {
      query: GET_WILLS_QUERY,
      update: (data) => {
        return data.getWills.rows;
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          filters: { userId: this.userId },
        };
      },
      skip() {
        return !this.isUserConsumer || !this.userId;
      },
    },
    cart: {
      query: GET_CART_ITEMS_BY_USER_ID_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.user?.id,
        };
      },
      skip() {
        return !this.user?.person_id;
      },
      update: (data) => data.getCartItemsByUserId,
    },
    affiliates: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          filters: { affiliateEnabled: true },
        };
      },
      update: (data) => data.getPartnerships,
    },
    invites: {
      query: GET_INVITES,
      variables() {
        return {
          userId: this.user?.id,
        };
      },
      skip() {
        return !this.user?.id;
      },
      update: (data) => data.getInvites,
    },
  },
  data() {
    return {
      supportedCountryOptions,
      userRole: '',
      userCountry: '',
      historicalWills: [],
      will: null,
      userNewEmail: '',
      user: null,
      person: null,
      loading: false,
      changeAffiliateId: null,
      affiliates: [],
      cart: [],
    };
  },
  computed: {
    ...mapGetters(['role']),
    userId() {
      return this.$route.params.id;
    },
    contactRows() {
      return [
        ['Name', this.person?.fullName],
        ['Alternative Name', this.person?.altFullName],
        ['ID', this.user?.id],
        ['Email', this.user?.email],
        ['Role', startCase(this.user?.role)],
        ['Verified', this.user?.verified],
        ['Country', this.person?.residentialAddress?.country],
      ];
    },
    subscriptionRows() {
      return [
        ['ID', this.subscription.id],
        ['Stripe Subscription id', this.subscription.stripeSubscriptionId],
        ['Auto renew', this.subscription.autoRenew],
        [
          'Payment method expires at',
          this.formattedSubscriptionPaymentMethodExpiryDate,
        ],
        ['Expires at', this.formattedSubscriptionExpiryDate],
        ['Created at', this.formattedSubscriptionCreatedAtDate],
      ];
    },
    isUserSuperAdmin() {
      return this.role === 'SUPER_ADMIN';
    },
    isUserConsumer() {
      return this.user?.role === 'CONSUMER';
    },
    showChangeUserRoleCard() {
      const subordinates = getSubordinateRolesByRole(this.role);
      return subordinates.length > 1 && subordinates.includes(this.user?.role);
    },
    showMasquerade() {
      return this.isAdminClassUser && this.$ff.enableMasquerade();
    },
    isAdminClassUser() {
      return isAdminOrHigher(this.role);
    },
    affiliateOptions() {
      return [
        {
          text: 'No affiliate',
          value: null,
        },
        ...this.affiliates.map((affiliate) => ({
          text: affiliate.name,
          value: affiliate.id,
        })),
      ];
    },
    affiliateData() {
      return this.affiliates.reduce((acc, affiliate) => {
        acc[affiliate.id] = affiliate.name;
        return acc;
      }, {});
    },
    invitedBy() {
      return this.will?.invite?.user?.email;
    },
    invitesSent() {
      return this.invites?.filter((invite) => invite.type !== 'PUBLIC');
    },
    invitesComplete() {
      return this.invites?.filter((invite) => !invite?.redeemable);
    },
  },
  async mounted() {
    await this.getUserData();
    this.changeAffiliateId = this.user.affiliateId;
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    ...mapActions('admin/masquerade', ['enterMasquerade']),
    ...mapActions('admin/users', ['setUsersSearchQuery']),
    ...mapActions('user/contacts', ['getDirectoryPersonData']),
    async verify() {
      if (!this.user?.verified) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: FORCE_VERIFY,
            variables: {
              id: this.user.id,
            },
          });

          this.user.verified = data.forceVerify.user.verified;
        } catch (e) {
          console.error(e.message);
        }
      }
    },
    changeAffiliate(affiliateId) {
      if (affiliateId === this.user.affiliateId) return;
      const affiliateName = affiliateId
        ? this.affiliateData[affiliateId]
        : 'no affiliate';

      this.$confirm.require({
        header: `Change affiliate to ${affiliateName}?`,
        message: `Are you sure you want to assign ${affiliateName} as the affiliate for ${this.user?.email}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.$apollo.mutate({
              mutation: UPDATE_USER_ADMIN,
              variables: {
                id: this.userId,
                userData: {
                  affiliateId: affiliateId || null,
                },
              },
            });
            this.user.affiliateId = affiliateId;
          } catch (error) {
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'error',
              text: formatError(error.message),
            });
            this.changeAffiliateId = this.user.affiliateId;
          }
        },
        reject: () => {
          this.changeAffiliateId = this.user.affiliateId;
        },
      });
    },
    changeUserRole({ target: { value: userNewRole } }) {
      const userRoleString = startCase(userNewRole);
      this.$confirm.require({
        header: `Change user role to ${userRoleString}?`,
        message: `Are you sure you want to assign ${userRoleString} role to ${this.user?.email}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.$apollo.mutate({
              mutation: UPDATE_USER_ROLE_MUTATION,
              variables: {
                id: this.user.id,
                role: userNewRole,
              },
            });
          } catch (error) {
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'error',
              text: formatError(error.message),
            });
          }
        },
        reject: () => {
          this.userRole = this.user?.role;
        },
      });
    },
    deleteUser() {
      this.$confirm.require({
        header: `Delete user ${this.user?.email}?`,
        message: 'All user information will be permanently deleted.',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.$apollo.mutate({
              mutation: DELETE_USER,
              variables: {
                userId: this.user.id,
              },
            });
            this.setUsersSearchQuery(null);
            this.$router.push({ path: this.localePath('/admin/users') });
          } catch (error) {
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'error',
              text: formatError(error.message),
            });
          }
        },
      });
    },
    changeCountry({ target: { value: userNewCountry } }) {
      if (userNewCountry === this.person.residentialAddress.country) {
        return;
      }
      this.$confirm.require({
        header: `Change the country to ${userNewCountry}?`,
        message: `Note: Changing from ${this.person.residentialAddress.country} to ${userNewCountry} will wipe sections of the current address.`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.$apollo.mutate({
            mutation: UPDATE_DIRECTORY_COUNTRY_MUTATION,
            variables: {
              id: this.person?.id,
              country: userNewCountry,
            },
          });
        },
        reject: () => {
          this.userCountry = this.person.residentialAddress.country;
        },
      });
    },
    changeEmail() {
      this.$confirm.require({
        header: `Change the email to ${this.userNewEmail}?`,
        message: 'Note: this email must be unique.',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.$apollo.mutate({
              mutation: UPDATE_USER_ADMIN,
              variables: {
                id: this.userId,
                userData: {
                  email: this.userNewEmail,
                },
              },
            });
            this.user.email = this.userNewEmail;
            this.userNewEmail = '';
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'success',
              text: `Changed users email`,
            });
          } catch (error) {
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'error',
              text: formatError(error.message),
            });
          }
        },
      });
    },
    async getUserData() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: GET_USER_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            id: this.userId,
          },
        });
        const user = data.getUser;
        this.user = user;
        this.userRole = user.role;

        if (user?.person_id && !this.person) {
          const person = await this.getDirectoryPersonData(user?.person_id);
          this.person = person;
          this.userCountry = person.residentialAddress.country;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
