
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliatePoaAboutYourselfPhone',
  computed: {
    ...mapFields('user/contacts', [
      'user.defaultPhoneNumber',
      'user.residentialAddress.country',
    ]),
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapGetters('user/poa', ['poaId']),
    submitLabel() {
      return this.defaultPhoneNumber?.value
        ? this.$t('forms.labels.continueToFinancial')
        : this.$t('forms.labels.skipToFinancial');
    },
    phoneNumber: {
      get() {
        const phoneNumber = {
          type: 'DEFAULT',
          prefix: this.defaultPhoneNumber?.prefix,
          value: this.defaultPhoneNumber?.value,
        };
        if (!phoneNumber.prefix) {
          phoneNumber.prefix = this.country === 'NZ' ? '+64' : '+61';
        }
        return phoneNumber;
      },
      set(value) {
        this.defaultPhoneNumber = value;
      },
    },
  },
  methods: {
    ...mapActions('user/contacts', ['updateDirectoryPerson']),
    async updatePhoneNumber() {
      this.$refs.form.resetError();

      try {
        await this.updateDirectoryPerson({
          id: this.userDetails.id,
          defaultPhoneNumber: this.phoneNumber.value ? this.phoneNumber : null,
        });
      } catch (error) {
        this.$refs.form.setError(error);
        return false;
      }

      if (this.userDetails.defaultPhoneNumber?.formatted) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          phone: `${this.userDetails.defaultPhoneNumber.formatted}`,
        });
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          poa_id: this.poaId,
          module_name: 'About Yourself',
        },
      });

      this.$router.push({
        path: this.localePath('/poa/financial'),
      });
    },
  },
};
