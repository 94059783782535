
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'OnboardingVaultItems',
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user-onboarding', [
      'currentStepData',
      'loading',
      'selectedVaultItemsIds',
    ]),
    selectedIds: {
      get() {
        return this.selectedVaultItemsIds;
      },
      set(value) {
        this.setSelectedVaultItemsIds(value);
      },
    },
  },
  methods: {
    ...mapActions('user-onboarding', [
      'saveVaultItems',
      'setSelectedVaultItemsIds',
    ]),
  },
};
