
import { formatError } from '@/utilities';
import DELETE_CART_ITEMS_MUTATION from '@/graphql/mutations/DeleteCartItems';

export default {
  name: 'AppCartTable',
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removeCartItem(itemName, itemId) {
      this.$confirm.require({
        header: `Delete the cart item ${itemName}?`,
        message: `Note: Wills & Subscriptions can only be added back by finishing the will modules.`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.$apollo.mutate({
              mutation: DELETE_CART_ITEMS_MUTATION,
              variables: {
                cartItemIds: itemId,
              },
            });
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'success',
              text: `Removed ${itemName} from the cart`,
            });
          } catch (error) {
            this.$nuxt.$emit('snackbar', {
              placement: 'top-right',
              type: 'error',
              text: formatError(error.message),
            });
          }
        },
      });
    },
  },
};
