
import { mapActions } from 'vuex';

export default {
  name: 'PagesAffiliateDashboardInvites',
  mounted() {
    this.getInvites();
  },
  methods: {
    ...mapActions('user/invites', ['getInvites']),
  },
};
