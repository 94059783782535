
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAssetsNotes',
  mixins: [will],
  data() {
    return {
      recipients: [],
      willTierTwoUpsellAddOnIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    submitLabel() {
      return this.willMeta.asset_note
        ? this.$t('forms.labels.continueToExecutors')
        : this.$t('forms.labels.skipToExecutors');
    },
    isComplete() {
      return !!(
        !this.willMeta.asset_note || this.willMeta.asset_note.length <= 3000
      );
    },
  },
  mounted() {
    this.unWatchAssetNotes = this.$watch(
      () => {
        return this.willMeta.asset_note;
      },
      this.setLegalAdviceVisibility,
      { immediate: true }
    );
  },
  methods: {
    setLegalAdviceVisibility() {
      if (this.willMeta.asset_note?.length) {
        this.willTierTwoUpsellAddOnIsVisible = true;
        if (this.unWatchAssetNotes) {
          this.unWatchAssetNotes();
        }
      }
    },
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Assets',
        },
      });

      this.$router.push({
        path: this.localePath('/will/executors'),
      });
    },
  },
};
