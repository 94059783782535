import { render, staticRenderFns } from "./index.vue?vue&type=template&id=768f425e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=768f425e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppProductNavigation: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/ProductNavigation.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppDecoratedText: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/DecoratedText.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppAccordionPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/AccordionPanel.vue').default})
