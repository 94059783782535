
import { fileIcon } from '@/utilities';

export default {
  name: 'AppFilesList',
  props: {
    files: {
      type: Array,
      required: true,
    },
    confirmUnlink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fileIcon,
    fileIsDeleted(fileStatus) {
      return fileStatus === 'DELETED';
    },
    unlinkFile(fileId) {
      if (this.confirmUnlink) {
        this.$confirm.require({
          header: this.$t('components.fileList.unlinkFile'),
          message: this.$t('components.fileList.unlinkFileMessage'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.$emit('unlinkFile', fileId);
          },
        });
      } else {
        this.$emit('unlinkFile', fileId);
      }
    },
  },
};
