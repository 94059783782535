
import { mapGetters, mapActions } from 'vuex';
import { gifts, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGiftsIncludeCharity',
  mixins: [gifts, will],
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('application/ui', [
      'giftWizardIsOpen',
      'shouldShowCharityConsentPage',
    ]),
    isComplete() {
      return !!(
        this.gifts.find((g) => g.charity !== null) ||
        !this.willMeta.charity_in_gifts
      );
    },
    submitLabel() {
      return this.$t('forms.labels.continue');
    },
  },
  async mounted() {
    await this.getGiftsData();
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    ...mapActions('will', ['getGiftsData']),
    getDisplayName(directoryPerson, charity) {
      return directoryPerson?.fullName || charity.displayName || charity.name;
    },
    async done() {
      if (this.willMeta.charity_in_gifts) {
        this.willMeta.has_gifts = true;
        await this.updateWillMeta();
      }
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Gifts',
        },
      });

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });
      await this.refetchWill();
      const path = this.localePath(
        this.shouldShowCharityConsentPage
          ? '/will/charity-consent?next-module=assets'
          : '/will/assets'
      );
      this.$router.push({
        path,
      });
    },
  },
};
