
import { mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';
import { charities } from '@/mixins/apollo';

export default {
  name: 'AppWillCharitySelector',
  mixins: [charities],
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    modelValue: {
      get() {
        if (!this.multiple && this.value.length) {
          return this.value[0];
        }
        return this.value;
      },
      set(newValue) {
        if (!this.multiple) {
          this.$emit('input', [newValue]);
        } else {
          this.$emit('input', newValue);
        }
      },
    },
  },
  mounted() {
    this.$nuxt.$on('addCharities', (addedCharities) => {
      this.charities.forEach((charity) => {
        addedCharities.forEach((addedCharity) => {
          if (isSameCharity(metaArrayToObject(charity.meta), addedCharity)) {
            this.selectionChange(true, charity);
          }
        });
      });
    });
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    selectionChange(checked, charity) {
      const index = this.modelValue.indexOf(charity.id);
      if (!checked && index > -1) {
        this.modelValue.splice(index, 1);
      } else if (this.multiple) {
        this.modelValue.push(charity.id);
      } else {
        this.modelValue = [charity.id];
      }
    },
  },
};
